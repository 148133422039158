// =====================================================================================================================
// Floating Form Inputs
//
// <editor-fold>
// =====================================================================================================================
.floating-label-text-input-group {
  display: grid;
  grid-template-areas: "all";
  position: relative;
  margin-bottom: 10px;

  &.has-errors {
    .floating-label-text-input-group__input {
      border-color: red;
    }
  }
}

.floating-label-text-input-group__input {
  grid-area: all;
  font-size: 1.8rem;
  height: 4.7rem;
  border: solid 1px var(--floating-label-text-input-border-color)!important;

  &::placeholder {
    opacity: 0;
  }
}

.floating-label-text-input-group__password-reveal-button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  right: 0;
  height: 4.7rem;
}

.floating-label-text-input-group__required-indicator {
  color: #fd4c4c;
}

.floating-label-text-input-group__error {
  color: red;
}

.floating-label-text-input-group__info {
  margin-bottom: 5px;
}

.floating-label-text-input-group__label-wrapper {
  grid-area: all;
  font-weight: 500;
  font-style: italic;
  color: var(--floating-label-text-input-label-color);
  height: 4.7rem;
  line-height: 4.7rem;
  padding-left: 1.8rem;
  margin: 0;
  position: absolute;
  transition: top 500ms ease-in-out;

  display: flex;
  justify-content: center;
  flex-direction: column;

  label {
    height: 2.4rem;
    background-color: white;
    margin: 0;
    padding: 0;
    width: 100%;
    display: inline-block;
    line-height: 2.4rem;
    font-weight: 500;
    font-size: 1.4rem;
    cursor: text;
  }

  &.is-active {
    color: var(--floating-label-text-input-label-active-color);
    position: absolute;
    top: -0.7rem;
    justify-content: flex-start;
    height: 1.4rem;

    label {
      background-color: white;
      line-height: 1.4rem;
    }
  }
}
// </editor-fold>