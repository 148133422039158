// Palette from https://app.clickup.com/2221054/v/dc/23rzy-511/23rzy-91
@mmna-red: #EE3124;
@mmna-black: #000000;
@mmna-white: #FFFFFF;
@mmna-dark-gray: #444444;
@mmna-gray: #929292;

// Additional colors not from palette
//@mmna-charcoal: #3E3E3E;
//@mmna-slate: #53575A;
//@mmna-gray: #B3B3B3;
@mmna-light-gray: #DFDFDF;
@mmna-lighter-gray: #F5F5F5;
//@mmna-dark-white: #F7F7F7;

// Theme Colors
@text-gray: #AAAAAA;
@white: #FFFFFF;
@black: #000000;
@dark-gray: #444444;
@line-gray: #7A7A7A;
@market-main: @mmna-red;
@market-hover: @mmna-red;
@light-gray: @mmna-light-gray;
@lighter-gray: @mmna-lighter-gray;

// Fonts
@sans-serif-font-stack: 'MMC', Arial, Helvetica, sans-serif;
@header-font-stack: 'MMC', Arial, Helvetica, sans-serif;

.juicer-feed a:hover {
  color: #b2b2b2;
}
.juicer-feed .j-meta a:hover {
  color: #000000;
}
.j-twitter-poster:hover {
  color: #b2b2b2;
}

:root {
  --market-main: @market-main;
  --mobile-nav-level2-bg: #CDCDCD;
  --mobile-nav-level2-fg: white;
  --mobile-nav-level3-bg: white;
  --mobile-nav-level3-fg: black;

  --floating-label-text-input-border-color: @mmna-red;
  --floating-label-text-input-label-color: @mmna-gray;
  --floating-label-text-input-label-active-color: @mmna-red;
}

@import '_base.less';
@import '../modules/newsroom/assets/styles/_floatingLabelTextInput';

html body {
  background: @mmna-light-gray;
  padding-bottom: 0!important;
}

a {
  transition: ease .3s;
}

// =====================================================================================================================
// MMNA Navbar
//
// <editor-fold>
// =====================================================================================================================

.basket-count-indicator {
  background: red;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  text-align: center;
  font-size: .85rem;
  padding: 8px;
  height: 1rem;
  width: 1rem;
  color: #fff;
  position: relative;
  top: -2px;

  &.clientBasketItemCount {
    display: none;
    &.clientBasketItemCount--full {
      display: inline-flex;
    }
  }
}


.navbar.site-navbar .navbar-container .custom-navbar-menu {
  li.menu-item {
    a {
      background: transparent;
      font-weight: 100;
      transition: ease .3s;
    }
    .submenu.dropdown-menu {
      background: @mmna-dark-gray;
      min-width: 190px;
      .submenu-item a {
        align-items: center;
        color: @white;
        display: flex;
        justify-content: space-between;
        &:hover {
          color: @mmna-light-gray;
        }
      }
    }
  }
}

#side-navbar {
  background-color: @mmna-dark-gray;
  .nav-action {
    color: @white;
    font-size: 1.6rem;
    font-weight: 400;
    padding: 0;
    text-transform: uppercase;
  }
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu > .dropdown-menu {
    border: none;
    border-radius: 0;
    margin-left: 0px;
    //margin-top: -6px;
    left: 100%;
    right: auto;
    top: 0;
}

.dropdown-submenu:hover > .dropdown-menu {
    display: block;
}

// </editor-fold>


// =====================================================================================================================
// Homepage
//
// <editor-fold>
// =====================================================================================================================
.homepage {
  &.homepage-container {
    background: @white;
  }

  &.homepage-container--alt {
    background-color: @white;
    @media(min-width: @screen-sm-min) {
      background-color: #f5f5f5;
    }
  }

  &.homepage-container--pb30 {
    padding-bottom: 15px;

    #content-type-tab-bar {
      .nav-tabs {
        .content-type {
          &.active {
            font-weight: initial;
          }
        }
      }
    }

    @media(min-width: @screen-sm-min) {
      padding-bottom: 30px;
    }
  }

  &.homepage-container--pb60 {
    padding-bottom: 15px;
    @media(min-width: @screen-sm-min) {
      padding-bottom: 30px;
    }
    @media(min-width: @screen-md-min) {
      padding-bottom: 60px;
    }
  }

  &.homepage-container--model-pullquote {
    background-color: @mmna-red;
  }
}

.last-homepage-container-desktop {
  padding-bottom: 30px;
  @media(min-width: @screen-sm-min) {
    padding-bottom: 30px;
  }
  @media(min-width: @screen-md-min) {
    padding-bottom: 95px;
  }
}

.homepage-carousel-outer-container {
  margin-bottom: 0px!important;
  @media(max-width: @screen-sm-max) {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}

.carousel-item__image {
  width: 100%;
}

.homepage.homepage-container.homepage-cta-container {
  background-color: @white;
  padding: 15px 0 0;
  @media(min-width: @screen-md-min) {
    background-color: @black;
    padding: 30px 0;
  }
  @media(max-width: @screen-sm-max) {
    width: 100%;
  }
}

.homepage.homepage-container.browse-by-model {
  background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 0%, rgba(51,51,51,1) 100%);
}

.homepage-cta {
  display: flex;
  align-items: center;
  padding: 10px;
  height: 80px;
  flex-direction: row;
  justify-content: space-between;
  @media(min-width: @screen-md-min) {
    padding: 30px;
    height: 120px;
  }
}

.homepage-cta__logo-container {
  flex-grow: 0;
  padding-right: 15px;
  @media(min-width: @screen-md-min) {
    padding-right: 30px;
  }
}

.homepage-cta__title-container {
  flex: 1;
}

.homepage-cta__title {
  color: @white;
  font-weight: 700;
  margin: 0;
  font-size: 1.8rem;
  padding-right: 10px;

  @media(min-width: @screen-sm-min) {
    font-size: 2.4rem;
    padding-right: 20px;
  }

  @media(min-width: @screen-md-min) {
    font-size: 3.6rem;
    padding-bottom: 6px;
  }

}

.homepage-cta__message {
  margin: 0;
  color: @white;
  font-size: 1.4rem;
  padding-right: 10px;
  font-weight: 400;

  @media(min-width: @screen-sm-min) {
    font-size: 1.8rem;
  }

  @media(min-width: @screen-md-min) {
    font-size: 2.2rem;
    padding-right: 20px;
  }
}

.homepage-cta__action-link {
  color: @white;
  font-size: 1.8rem;
  font-weight: 400;
  @media(min-width: @screen-sm-min) {
    font-size: 2.4rem;
  }

  @media(min-width: @screen-md-min) {
    font-size: 3.2rem;
  }
  i {
    font-size: 2.4rem;
    color: @mmna-red;
  }
}

// -------------
// Latest News
// -------------
.homepage {
  #latest-news {
    padding-bottom: 15px;
    .homepage-section-header {
      .homepage-section__display-style-toggle-container {
        flex: 1;
        text-align: left;
        a {
          font-size: 20px;
          margin-right: 5px;
        }
      }
      .homepage-section__title {
        flex: 1;
      }
      .homepage-section__action-buttons {
        flex: 1;
        text-align: right;
      }
    }
  }
}
.homepage-latest-news__header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  margin-top: 15px;
  @media(min-width: @screen-sm-min) {
    margin-bottom: 25px;
    margin-top: 25px;
  }
  @media(min-width: @screen-md-min) {
    margin-bottom: 30px;
    margin-top: 60px;
  }
  h1 {
    color: @black;
    font-size: 1.8rem;
    font-weight: 700;
    margin: 0;
    text-transform: uppercase;
    @media(min-width: @screen-sm-min) {
      font-size: 2.8rem;
    }
    @media(min-width: @screen-md-min) {
      font-size: 3.2rem;
    }
  }
}

.browse-topics-dropdown-menu {
  width: 100%;
  border-radius: 0;
}

.release-grid {
  .release-item-thumbnail-image__list { display: none; }

}

.release-grid-mixin(@sm-to-md: 2, @lg: 3) {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 0;
  flex-direction: row;
  .release-item {
    display: grid;
    grid-template-areas: "image information"
                         "image actions";
    grid-template-rows: auto auto;
    grid-template-columns: 36% 64%;
    @media(min-width: @screen-sm-min) {
      grid-template-areas: "image"
                           "information";
      grid-template-rows: 250px auto;
      grid-template-columns: 100%;
      &:hover, &:focus {
        .release-grid__actions {
          opacity: 1;
        }
      }
    }

    @media (min-width: @screen-sm-min) and (max-width: @screen-md-max) {
      border-left: solid 7px @white;
      border-right: solid 7px @white;
      margin-bottom: 15px;
      width: calc(100% / @sm-to-md);
      &:nth-child(@{sm-to-md}n) {
        border-right: none;
      }
      &:nth-child(@{sm-to-md}n+1) {
        border-left: none;
      }
      a {
        width: 100%;
      }
    }

    @media (min-width: @screen-lg-min) {
      border-left: solid 7px @white;
      border-right: solid 7px @white;
      flex-direction: column;
      margin-bottom: 15px;
      width: calc(100% / @lg);
      padding: 0!important;
      &:nth-last-child(-n+@{lg}) {
        //margin-bottom: 0;
      }
      &:nth-child(@{lg}n) {
        border-right: none;
      }
      &:nth-child(@{lg}n+1) {
        border-left: none;
      }
      a {
        width: 100%;
      }
    }
    .release-item__image {
      grid-area: image;
      margin-right: 10px;
      @media(min-width: @screen-sm-min) {
        margin-right: 0;
        -ms-grid-row-span: 1;
      }
    }
    .release-grid__photo {
      display: none;
      width: 100%;
      height: 250px;
      @media(min-width: @screen-sm-min) {
        display: block;
      }
    }
    .release-list__photo {
      display: block;
      max-width: 100%;
      @media(min-width: @screen-sm-min) {
        display: none;
      }
    }
    .release-item__information-container {
      grid-area: information;
      @media(min-width: @screen-sm-min) {
        background: #222;
        padding: 10px 15px 15px;

        -ms-grid-row: 2;
        -ms-grid-column: 1;
      }
      a {
        color: @black;
        transition: ease .3s;
        .title {
          font-size: 1.4rem;
          font-weight: 400;
        }
        .published {
          font-size: 1.2rem;
          font-weight: 400;
          color: @mmna-gray;
        }
        @media(min-width: @screen-sm-min) {
          color: @white;
          &:hover, &:focus {
            color: @market-main;
          }
        }
      }
    }
    .release-grid__actions {
      background-color: transparent;
      grid-area: actions;
      position: relative;
      padding: 0;
      transition: ease .3s;
      @media(min-width: @screen-sm-min) {
        background-color: rgba(0, 0, 0, 0.6);
        top: 0;
        left: 0;
        grid-area: image;
        padding: 10px 0 10px 10px;
        position: absolute;

        -ms-grid-row-span: 1;
        opacity: 0;
      }
      a {
        color: @mmna-gray;
        font-size: 20px;
        padding: 0 10px 0 0;
        @media(min-width: @screen-sm-min) {
          color: @white;
          padding: 0 7px;
        }
        &:hover {
          color: @mmna-red;
        }
        &:last-child {
          padding-right: 15px;
        }
      }
    }
  }
}

.release-grid .release-container {
  // No matter what, if being asked to display a release-grid, do it.
  .release-grid-mixin(2, 3);
}

// ---------------------------------
// Executive Bios
// ---------------------------------
.tab-pane.channel-tab.executive-bios {
  margin: 0;
}
.executive-bios-release-grid-mixin(@xs: 2, @sm-to-md: 3, @lg: 4) {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 0;
  flex-direction: row;
  .release-item {
    display: grid;
    grid-template-areas: "image"
    "information" "actions";
    grid-template-rows: 200px auto auto;
    grid-template-columns: 100%;
    @media(min-width: @screen-sm-min) {
      grid-template-areas: "image"
      "information";
      grid-template-rows: 250px auto;
    }
    @media(min-width: @screen-md-min) {
      grid-template-rows: 300px auto;
    }
    @media(min-width: @screen-lg-min) {
      grid-template-rows: 350px auto;
    }
    @media(max-width: @screen-xs-max) {
      border-left: solid 5px @white;
      border-right: solid 5px @white;
      margin-bottom: 0px;
      width: calc(100% / @xs);
      &:nth-child(@{xs}n) {
        border-right: none;
      }
      &:nth-child(@{xs}n+1) {
        border-left: none;
      }
      a {
        width: 100%;
      }
    }
    @media (min-width: @screen-sm-min) and (max-width: @screen-md-max) {
      border-left: solid 7px @white;
      border-right: solid 7px @white;
      margin-bottom: 15px;
      width: calc(100% / @sm-to-md);
      &:nth-child(@{sm-to-md}n) {
        border-right: none;
      }
      &:nth-child(@{sm-to-md}n+1) {
        border-left: none;
      }
      a {
        width: 100%;
      }
    }

    @media (min-width: @screen-lg-min) {
      border-left: solid 7px @white;
      border-right: solid 7px @white;
      flex-direction: column;
      margin-bottom: 15px;
      width: calc(100% / @lg);
      padding: 0!important;
      &:nth-last-child(-n+@{lg}) {
        //margin-bottom: 0;
      }
      &:nth-child(@{lg}n) {
        border-right: none;
      }
      &:nth-child(@{lg}n+1) {
        border-left: none;
      }
      a {
        width: 100%;
      }
    }
    .release-item__image {
      grid-area: image;
      margin-right: 0;
    }
    .release-grid__photo {
      display: block;
      width: 100%;
      height: 200px;
      @media(min-width: @screen-sm-min) {
        height: 250px;
      }
      @media(min-width: @screen-md-min) {
        height: 300px;
      }
      @media(min-width: @screen-lg-min) {
        height: 350px;
      }
    }
    .release-item__information-container {
      grid-area: information;
      background: transparent;
      padding: 10px 0 0;
      @media(min-width: @screen-sm-min) {
        background: @black;
        padding: 15px;
      }
      a {
        color: @black;
        @media(min-width: @screen-sm-min) {
          color: @white;
        }
        .title {
          font-size: 1.4rem;
          font-weight: 400;
          @media(min-width: @screen-sm-min) {
            font-size: 1.6rem;
            font-weight: 700;
          }
        }
      }
    }
    .release-grid__actions {
      background-color: transparent;
      grid-area: actions;
      position: relative;
      padding: 0;
      @media(min-width: @screen-sm-min) {
        background-color: rgba(0, 0, 0, 0.6);
        bottom: 0;
        left: 0;
        grid-area: image;
        padding: 10px 0 10px 10px;
        position: absolute;
      }
      a {
        color: @mmna-gray;
        font-size: 20px;
        padding: 0 10px 0 0;
        @media(min-width: @screen-sm-min) {
          color: @white;
          padding: 0 7px;
        }
        &:hover {
          color: @mmna-red;
        }
        &:last-child {
          padding-right: 15px;
        }
      }
    }
  }
}
.executive-bios {
  .release-grid .release-container {
    // No matter what, if being asked to display a release-grid, do it.
    .executive-bios-release-grid-mixin(2, 3, 4);
  }
}

.release-body__title {
  margin: 0 0 10px 0;
  color: black;
  font-size: 2.2rem;
  line-height: 2.6rem;
  @media (min-width: @screen-sm-min) {
    font-size: 3.4rem;
    line-height: 3.8rem;
  }
  @media (min-width: @screen-md-min) {
    font-size: 4rem;
    line-height: 5.2rem;
  }
}

.release-body--executive-bio__name {
  margin: 0 0 10px 0;
  color: black;
  text-transform: uppercase;
  font-size: 2.2rem;
  @media (min-width: @screen-sm-min) {
    font-size: 3.5rem;
  }
  @media (min-width: @screen-md-min) {
    font-size: 6rem;
  }
}

.release-body--executive-bio__title {
  font-style: italic;
  font-size: 1.4rem;
  font-weight: 300;
  color: @mmna-dark-gray;
  @media (min-width: @screen-sm-min) {
    font-size: 2.5rem;
  }
  @media (min-width: @screen-md-min) {
    font-size: 3.5rem;
  }
}

.release-body--executive-bio__organization {
  font-style: italic;
  font-size: 1.4rem;
  font-weight: 300;
  color: @mmna-dark-gray;
  margin-bottom: 10px;
  @media (min-width: @screen-sm-min) {
    font-size: 2.5rem;
  }
  @media (min-width: @screen-md-min) {
    font-size: 3.5rem;
  }
}

.release-body--presskit {
  max-width: 760px;
}

// -------------
// Subscribe
// -------------
.subscribe-banner {
  align-items: center;
  background-color: @mmna-red;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  margin-top: 20px;
  padding: 30px;
  height: 96px;
  @media(min-width: @screen-sm-min) {
    margin-bottom: 30px;
    margin-top: 20px;
  }
  @media(min-width: @screen-md-min) {
    margin-bottom: 60px;
    margin-top: 0;
  }
  @media(max-width: @screen-xs-max) {
    flex-direction: column;
    margin-left: -15px;
    margin-right: -15px;
    margin-top: 15px;
    margin-bottom: 15px;
    padding: 15px;
    height: auto;
  }
  h2 {
    margin: 0 0 10px;
    color: @white;
    font-size: 2rem;
    font-weight: 700;
    text-transform: uppercase;
    @media(min-width: @screen-sm-min) {
      font-size: 2.6rem;
      margin: 0;
    }
  }
  .mmna-btn.visible-xs-block {
    @media(max-width: @screen-xs-max) {
      display: flex!important;
      justify-content: space-between;
      width: 100%;
    }
  }
}

// ----------------
// Browse by Model
// ----------------
.browse-by-model {
  padding: 60px 0;
  .browse-by-model__header {
    text-align: center;
    h1 {
      color: @white;
      font-size: 1.8rem;
      font-weight: 700;
      margin: 0 0 2px 0;
      text-transform: uppercase;
      @media(min-width: @screen-sm-min) {
        font-size: 2.8rem;
      }
      @media(min-width: @screen-md-min) {
        font-size: 4.4rem;
      }
    }
    p {
      color: @white;
      font-size: 14px;
      font-weight: 400;
      @media(min-width: @screen-sm-min) {
        font-size: 1.8rem;
      }
    }
  }
  .browse-by-model__item {
    margin-top: 20px;
    text-align: center;
    display: block;
    @media(min-width: @screen-sm-min) {
      margin-top: 30px;
    }
    img {
      height: 64px;
      @media(min-width: @screen-sm-min) {
        height: 100px;
      }

      margin-bottom: 15px;
    }
    p {
      color: @white;
    }
  }
}

// ----------------
// Corporate News
// ----------------
.homepage-corporate-news {


}

.homepage-corporate-news__header, .homepage-social-media__header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  margin-top: 15px;
  @media(min-width: @screen-sm-min) {
    margin-bottom: 25px;
    margin-top: 25px;
  }
  @media(min-width: @screen-md-min) {
    margin-bottom: 30px;
    margin-top: 60px;
  }
  h1 {
    color: @black;
    font-size: 1.8rem;
    font-weight: 700;
    margin: 0;
    text-transform: uppercase;
    @media(min-width: @screen-sm-min) {
      font-size: 3.2rem;
    }
  }
}

.homepage-corporate-news__release-container {
  .release-list-mixin();

  .release-item > a.release-list__photo {
    flex: 0.50;
  }

  .release-item {
    margin-bottom: 10px;
    background-color: @white;
    padding: 0;
    transition: ease .3s;
    @media(min-width: @screen-sm-min) {
      background-color: @dark-gray;
      height: 131px;
    }
    .content-title a {
      font-size: 1.4rem;
      font-weight: 100;
      color: @black;
      @media(min-width: @screen-sm-min) {
        color: @mmna-white;
      }
    }

    .pub-date {
      color: @mmna-gray;
      font-size: 12px;
      font-weight: 400;
      text-transform: none;
    }

    .release-list__actions {
      a, button {
        color: @mmna-gray;
        font-size: 18px;
        margin-right: 5px;
      }

      button {
        padding: 0;
        background-color: transparent;
        border: none;
      }
    }

    &:hover {
      background-color: @mmna-red;
      .release-list__actions {
        a, button {
          color: @mmna-white;
        }
      }
      .pub-date {
        color: @mmna-white;
      }
    }

  }

  .release-list__photo {
    background-position: center;
    background-size: cover;
    display: block;
  }

  .release-information {
    padding: 0 0 0 10px;
    @media(min-width: @screen-sm-min) {
      padding: 15px;
    }
  }
  .content-title {
    margin-bottom: 5px;
    a {
      color: @white;
      font-size: 14px;
      font-weight: 700;
    }
  }
}


// ----------------
// Search Footer
// ----------------
.homepage-search-footer {
  background-color: @mmna-red;
  padding: 10px 0;
  @media(min-width: @screen-sm-min) {
    padding: 30px 0;
  }
  h2 {
    color: @white;
    display: flex;
    font-size: 1.8rem;
    font-weight: 700;
    justify-content: center;
    margin: 0 0 10px;
    text-transform: uppercase;
    @media(min-width: @screen-sm-min) {
      font-size: 2.6rem;
      margin: 0 0 15px;
    }
  }
  .homepage-search-footer-search-container {
    display: flex;
    justify-content: center;
    input {
      border: none;
      height: 40px;
    }

    .input-group {
      width: 300px;
    }

    .input-group-addon {
      background: @white;
      border: none;
      color: @mmna-red;
    }
  }
}

// </editor-fold>


// =====================================================================================================================
// Release Show
//
// <editor-fold>
// =====================================================================================================================
.release-show-actions {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  margin-top: 15px;
  @media(min-width: @screen-md-min) {
    margin-bottom: 30px;
    margin-top: 30px;
  }
}

.release-show-actions__share {
  margin-left: auto;
  display: flex;
  align-items: center;

  .icon-button {
    color: white;
    width: 31px;
    height: 31px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 0;
    margin-left: 2px;


    &.facebook {
      background-color: @facebook-blue;
    }

    &.twitter {
      background-color: @twitter-blue;
    }

    &.linkedin {
      background-color: @linkedin-blue;
    }
  }
}

.release-contacts {
  display: flex;
  flex-wrap: wrap;
  //font-size: 1.4rem;
  //line-height: 1.8rem;

  @media(min-width: @screen-sm-min) {
    //font-size: 1.8rem;
    //line-height: 2.2rem;
  }
}

.release-contacts-item {
  display: flex;
  //background-color: @mmna-dark-white;

  //padding: 10px;
  margin-bottom: 10px;
  width: 100%;
  flex-wrap: wrap;

  @media(min-width: @screen-sm-min) {
    flex: 1;
    //padding: 10px;
    //margin-bottom: 10px;
    //width: ~"calc(50% - 5px)";

    //&:nth-child(odd) {
    //  margin-right: 5px;
    //}
    //
    //&:nth-child(even) {
    //  margin-left: 5px;
    //}
  }

  @media(min-width: @screen-md-min) {
    flex: .5;

    //padding: 20px;
    //margin-bottom: 20px;
    //width: ~"calc(50% - 10px)";
    &:nth-child(odd) {
      padding-right: 5px;
    }

    &:nth-child(even) {
      padding-left: 5px;
    }
  }
}

.release-contacts-item__detail-panel {
  flex: 1;
  font-size: 1.4rem;
}

.release-contacts-item__panel-title {
  color: @mmna-red;
  text-transform: uppercase;
  font-weight: 900;
  margin-bottom: 5px;
}

.release-contacts-item__name {
  font-weight: 700;
  font-size: 1.8rem;
}

.release-contacts-item__title {
  margin-bottom: 5px;
}

.release-contacts-item__email {
  font-size: 1.2rem;
  i { font-size: 1.8rem; margin-right: 5px; }
  a { color: @mmna-red; }
}


.release-contacts-item__phone-number {
  font-size: 1.2rem;

  i { font-size: 1.8rem; margin-right: 5px; }
  a { color: @mmna-red; }
}



.release-contacts-item__headshot {
  //width: 80px;
  //height: 80px;
  margin-right: 10px;

  @media(min-width: @screen-sm-min) {
    width: 100px;
    height: 100px;
    margin-right: 10px;
    flex: 0 0 100px;
  }
}

.presskit-component-release {
  display: none;

  &.is-visible {
    display: block;
  }
}


.grid {

  &.grid--release-image-gallery {

    .grid__item, .grid__col-sizer {
      width: 49%;
      @media (min-width: @screen-sm-min) {
        width: 32.5%;
      }
      @media (min-width: @screen-md-min) {
        width: 32.5%;
      }
    }

  }

  &.grid--release-video-collection {

    .grid__item, .grid__col-sizer {
      width: 49%;
      @media (min-width: @screen-sm-min) {
        width: 32.5%;
      }
      @media (min-width: @screen-md-min) {
        width: 49.5%;;
      }
    }

  }

  &.grid--release-executive-bio-headshots {

    .grid__item, .grid__col-sizer {
      width: 49%;
      @media (min-width: @screen-sm-min) {
        width: 49.5%;
      }
      @media (min-width: @screen-md-min) {
        width: 49.5%;
      }
    }

  }
}

.release-sidebar-header {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.btn.sidebar-btn {
  background: transparent;
  color: @black;
  font-size: 2rem;
  margin: 10px 0;
  padding: 0;
  &:hover, &:focus {
    color: @market-main;
  }
}
.sidebar-photo-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.photo-thumb-widget {
  #photo-sidebar-carousel {
    .item {
      background-color: @black;
      width: 100%;
      img {
        width: auto;
        height: auto;
        margin: auto;
        max-width: 100%;
      }
      .sidebar-item-count {
        position: absolute;
        background: rgba(0,0,0,0.5);
        color: white;
        padding: 9px;
        top: 10px;
        left: 10px;
        font-size: 12px;
      }
    }
    .item.active {
      display: inline-block;
      position: relative;
    }
  }
  .custom-carousel-caption {
    display: none;
    //bottom: 0;
    //left: 15px;
    //margin-top: 10px;
    //padding: 0;
    //text-align: left;
    a.title {
      //color: @black;
      //text-shadow: none;
      transition: ease-in-out .25s;
    }
  }
  .custom-carousel-caption.active {
    display: block;
  }
}
// </editor-fold>



// =====================================================================================================================
// MMNA Site Footer
//
// <editor-fold>
// =====================================================================================================================

.site-footer {
  background: @black;
  position: absolute;
  width: 100%;
  bottom: 0;
  height: @mobile-footer-height;
  @media(min-width: @screen-sm-min) {
    height: @tablet-footer-height;
  }
  @media(min-width: @screen-md-min) {
    height: @desktop-footer-height;
  }
  .footer-row {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 25px 0;
    @media(min-width: @screen-sm-min) {
      flex-direction: row;
    }
  }
  .site-footer-container {
    align-items: center;
    height: 157px;
    font-weight: 400;
    padding: 25px 0;
    @media(min-width: @screen-sm-min) {
      height: 80px;
      padding: 0;
    }
    @media(min-width: @screen-md-min) {
      height: 140px;
    }
  }
  .site-footer__links {
    color: @white;
    display: flex;
    width: auto;
    @media(min-width: @screen-sm-min) {
      font-size: 1.2rem;
      width: 44%;
    }
    @media(min-width: @screen-md-min) {
      font-size: 1.4rem;
      width: 33%;
    }
    a {
      color: @white;
      font-size: 1.2rem;
      padding: 0 7px;
      @media(min-width: @screen-md-min) {
        font-size: 1.4rem;
        padding: 0 0 0 15px;
      }
    }
  }
  .site-footer__logo {
    img {
      height: 50px;
      @media(min-width: @screen-md-min) {
        height: 80px;
      }
    }
  }
  .site-footer__actions {
    display: flex;
    justify-content: flex-end;
    width: auto;
    @media(min-width: @screen-sm-min) {
      font-size: 1.2rem;
      width: 44%;
    }
    @media(min-width: @screen-md-min) {
      font-size: 1.4rem;
      width: 33%;
    }
    ul {
      @media(max-width: @screen-xs-max) {
        display: flex;
        font-size: 1.2rem;
      }
    }
    a {
      background: transparent!important;
      color: @white;
      text-transform: uppercase;
      @media(min-width: @screen-sm-min) {
        padding: 10px;
      }
      @media(min-width: @screen-md-min) {
        padding: 10px 15px;
      }
    }
  }
}
// </editor-fold>

// =====================================================================================================================
// MMNA Channel Banners
//
// <editor-fold>
// =====================================================================================================================
.header-banner-outer {
  // The background image is actually specified via a style tag in the template - this is just here as a fallback.
  background: linear-gradient(90deg, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0.1) 50%, rgba(0,0,0,0) 100%), url(images/MMNADefaultChannelBanner.min.jpg);
  background-repeat: no-repeat, no-repeat;
  height: 150px;
  background-position: center, center;
  background-size: cover;
  display: grid;
  grid-template-areas: "banner"
                       "tab-bar";
  grid-template-rows: auto 40px;
  &.header-banner-outer--h340 {
    height: 120px;
    @media(min-width: @screen-sm-min) {
      height: 182px;
    }
    @media(min-width: @screen-md-min) {
      height: 340px;
    }
  }
  @media(min-width: @screen-sm-min) {
    height: 234px;
    grid-template-rows: auto 70px;
  }
  @media(min-width: @screen-md-min) {
    height: 440px;
  }
}

.header-banner-inner {
  grid-area: banner;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media(max-width: @screen-xs-max) {
    padding: 0 15px;
  }
}

.header-banner-title__canned-text {
  font-size: 1.4rem;
  font-weight: 400;
  color: @mmna-white;
  line-height: 1.4rem;
  text-transform: uppercase;
  @media(min-width: @screen-sm-min) {
    font-size: 2.5rem;
    line-height: 2.5rem;
  }
  @media(min-width: @screen-md-min) {
    font-size: 3.5rem;
    line-height: 3.5rem;
  }
}

.header-banner-title__page-title {
  font-size: 1.8rem;
  font-weight: 700;
  color: @mmna-white;
  line-height: 1.8rem;
  @media(min-width: @screen-sm-min) {
    font-size: 3.5rem;
    line-height: 3.5rem;
  }
  @media(min-width: @screen-md-min) {
    font-size: 6.5rem;
    line-height: 6.5rem;
  }
}

.header-banner-title__page-title--basket {
  text-transform: uppercase;
}

.header-banner-tab-bar {
  grid-area: tab-bar;
  display: flex;
}

.header-banner-tab-bar__left-spacer {
  display: none;

  @media(min-width: @screen-xs-min) {
    display: block;
    flex: 1;
    background-color: #ee3124;
  }
}

.header-banner-tab-bar__tab-bar {
  display: flex;
  width: 100%;
  @media(min-width: @screen-sm-min) {
    width: 728px;
  }
  @media(min-width: @screen-md-min) {
    width: 940px;
  }
  @media(min-width: @screen-lg-min) {
    width: 1140px;
  }
}

.header-banner-tab-bar__tab-bar--release {
  #content-type-tab-bar {
    padding: 0;
    @media(min-width: @screen-sm-min) {
      padding: 0 15px;
    }
    @media(min-width: @screen-md-min) {
      padding: 0;
    }
  }
}

.header-banner-tab-bar__items {
  background-color: #ee3124;
  @media(max-width: @screen-xs-max) {
    width: 100%;
  }
}

.header-banner-tab-bar__action-buttons-outer {
  background-color: rgba(0, 0, 0, 0.41);
  flex: 1;
  display: none;
  @media(min-width: @screen-sm-min) {
    display: flex;
  }
  .triangle-container();

  .header-banner-tab-bar__action-buttons-triangle {
    .triangle-top-left(70px, 70px, @mmna-red);
  }


}

.header-banner-tab-bar__action-buttons-inner {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.header-banner-tab-bar__action-button {
  display: flex;
  align-items: center;
  margin-right: 15px;

  &:last-child {
    margin-right: 0;
  }
}

.header-banner-tab-bar__right-spacer {
  display: none;

  @media(min-width: @screen-xs-min) {
    display: block;
    flex: 1;
    background-color: rgba(0, 0, 0, 0.41);
  }
}

// </editor-fold>

// =====================================================================================================================
// MMNA Model Channel
//
// <editor-fold>
// =====================================================================================================================


.model-content-container {
  .grid {
    .grid__item, .grid__col-sizer {
      width: 49%;
      @media (min-width: @screen-sm-min) {
        width: 32.5%;
      }
      @media (min-width: @screen-md-min) {
        width: 32.5%;
      }
    }

    .grid__gutter-sizer {
      width: 2%;
      @media(min-width: @screen-sm-min) {
        width: 1.25%;
      }
      @media (min-width: @screen-md-min) {
        width: 1.25%;
      }
    }
  }
}



.model-channel-quote-inner {
  //display: flex;
  padding-bottom: 15px;
  padding-top: 15px;
  font-size: 5rem;
  align-items: center;
  @media(min-width: @screen-sm-min) {
    padding-bottom: 30px;
    padding-top: 30px;
  }
  @media(min-width: @screen-md-min) {
    padding-bottom: 60px;
    padding-top: 60px;
  }
  i {
    color: rgba(0,0,0,0.15);
    margin-right: 190px;
    font-size: 10rem;
    display: none;
  }
}

.model-channel-quote__quote {
  float: right;
  color: white;
  font-size: 1.8rem;
  font-style: italic;
  margin: 0;
  text-align: right;
  @media(min-width: @screen-sm-min) {
    font-size: 2.5rem;
  }
  @media(min-width: @screen-md-min) {
    font-size: 5rem;
  }
}

.model-channel-quote__quote-source {
  float: right;
  color: white;
  font-size: 1.4rem;
  font-weight: 400;
  margin: 5px 0 0;
  @media(min-width: @screen-sm-min) {
    font-size: 1.8rem;
    margin: 10px 0 0;
  }
  @media(min-width: @screen-md-min) {
    font-size: 2.5rem;
    margin: 20px 0 0;
  }
}
// </editor-fold>


// =====================================================================================================================
// MMNA Errors
//
// <editor-fold>
// =====================================================================================================================
.error-container {
  display: flex;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  padding: 15px;
  background-color: black;
  color: white;

  @media(min-width: @screen-sm-min) {
    padding-top: 100px;
    justify-content: top;
    align-items: center;
  }

  @media(min-width: @screen-md-min) {
    padding-top: 0;
    justify-content: center;
    align-items: center;
  }
}

.error-info-panel {
  display: grid;
  grid-template-columns: min-content auto;
  grid-template-areas: "title icon"
                       "message message";

  @media(min-width: @screen-sm-min) {
    grid-template-columns: auto auto;
    text-align: center;
    grid-template-areas: "icon"
                         "title"
                         "message";
  }
}

.error-info-panel__icon {
  color: #FFC843;
  font-size: 2.4rem;
  grid-area: icon;
}

.error-info-panel__title {
  grid-area: title;
  font-weight: 700;
  font-size: 2rem;
  margin-top: 3px;
  white-space: nowrap;
  padding-right: 5px;

  @media(min-width: @screen-sm-min) {
    font-size: 2.4rem;
    margin-top: 20px;
  }
}

.error-info-panel__message {
  grid-area: message;
}
// </editor-fold>


// =====================================================================================================================
// MMNA Buttons
//
// <editor-fold>
// =====================================================================================================================
.mmna-btn {
  border: none;
  border-radius: 1px;
  font-weight: 100;
  font-size: 1.6rem;
  padding: 8px 16px;
  text-transform: uppercase;
  transition: ease .3s;
  i {
    padding-left: 10px;
  }
}

.mmna-btn--black {
  background: @black;
  color: @white;
  &:hover, &:active, &:focus {
    color: @mmna-red;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.4);
  }
  i {
    color: @mmna-red;
  }
}

.mmna-btn--transparent {
  align-items: center;
  background: transparent;
  color: @black;
  display: inline-flex;
  font-size: 1.6rem;
  text-transform: capitalize;
  padding-right: 0;
  &:hover {
    color: @mmna-red;
  }
  i {
    color: @mmna-red;
    font-size: 2.8rem;
    padding: 0 5px 0 0;
  }
}

.btn--extra-padding-w {
  padding-left: 4%;
  padding-right: 4%;
}

.btn--12p-padding-w {
  padding-left: 12%;
  padding-right: 12%;
}

.btn-0-padding-h {
  padding-top: 0;
  padding-bottom: 0;
}

.btn-3-padding-h {
  padding-top: 3px;
  padding-bottom: 3px;
}

.btn--bigbody {
  text-align: left;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  @media(min-width: @screen-sm-min) {
    text-align: center;
    i { margin-left: 110px; }
    width: auto;
  }
}

.mmna-btn--no-text-transform {
  text-transform: none!important;
}

.mmna-btn--release-item-action {
  align-items: center;
  background: transparent;
  color: @black;
  display: inline-flex;
  font-size: 1.4rem;
  text-transform: uppercase;
  font-weight: 100;
  padding-left: 0;
  @media(max-width: @screen-xs-max) {
    padding-right: 5px;
  }
  &:hover {
    color: @mmna-red;
  }
  i {
    color: @mmna-red;
    font-size: 2rem;
    padding: 0 5px 0 0;
    @media(max-width: @screen-xs-max) {
      color: @mmna-gray;
      padding: 0;
    }
  }
}

.mmna-btn--grid-item {
  &.saved-basket-item i {
    color: var(--market-main)!important;
  }
  align-items: center;
  background: transparent;
  color: @black;
  display: inline-flex;
  font-size: 1.4rem;
  text-transform: uppercase;
  font-weight: 100;
  border-radius: 0;
  padding: 5px 0px;
  @media(min-width: @screen-sm-min) {
    padding: 10px 5px;
  }
  i {
    color: @mmna-gray;
    font-size: 2rem;
    padding: 0 5px 0 0;
    @media(min-width: @screen-sm-min) {
      color: @mmna-white;
    }
    &:hover {
     color: @mmna-red;
    }
  }
}
.mmna-btn--release-show-action {
  padding: 0;
  border: 0;
  background: none;
  text-transform: uppercase;
  font-size: 1.4rem;
  font-weight: 100;
  display: inline-flex;
  margin-right: 10px;

  i {
    margin-left: 5px;
    color: @mmna-red;
    font-size: 2rem;
  }
}
.mobile-homepage-section-btn {
  width: 100%;
}
// </editor-fold>


.side-by-side-input-group {
  flex-direction: column;
  align-items: stretch;
  display: flex;
  margin-bottom: 10px;
  text-transform: lighter;
  @media(min-width: @screen-sm-min) {
    align-items: center;
    flex-direction: row;
  }
}
.side-by-side-input-group .side-by-side-input-group__input {
  flex: 0.7;
  order: 2;
  border: none;
  height: 5rem;
  background-color: @mmna-lighter-gray;
}
.side-by-side-input-group .side-by-side-input-group__label-wrapper {
  flex: 0.3;
  order: 1;
  font-weight: 400!important;

  label {
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 400;
    @media(min-width: @screen-sm-min) {
      font-size: 15px;
      text-transform: uppercase;
      font-weight: 700;
    }
  }
}

// =====================================================================================================================
// MMNA Modal Dialog
//
// <editor-fold>
// =====================================================================================================================
.modal-social-actions {
  margin-bottom: 10px;
  a {
    color: #b3b3b3;
    font-size: 20px;
    margin-right: 5px;
    transition: ease-in-out .25s;
    &:focus, &:hover {
      color: var(--market-main);
    }
  }
}
// </editor-fold>

// =====================================================================================================================
// MMNA Share Modal
//
// <editor-fold>
// =====================================================================================================================
#share-basket-modal {
  .panel-body {
    padding: 15px;
  }
  .site-page-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 0;
    h2 {
      margin: 0;
    }
  }
}
// </editor-fold>

// =====================================================================================================================
// MMNA Basket
//
// <editor-fold>
// =====================================================================================================================

.tab-content-controls {
  margin-bottom: 30px;

  .btn {
    margin-right: 10px;
  }
}

#basket-news-controls, #basket-photos-controls, #basket-videos-controls {
  @media(max-width: @screen-xs-max) {
    display: flex;
    margin-bottom: 15px;
  }
  a {
    @media(max-width: @screen-xs-max) {
      flex: 1;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
#basket-videos-controls {
  a {
    @media(max-width: @screen-xs-max) {
      margin-right: 0;
    }
  }
}

// </editor-fold>

body.site--mmna {
  font-weight: 400; // The MMNA font @ 400 is too chonky.
  padding-top: 50px;
  padding-bottom: @mobile-footer-height!important;
  @media(min-width: @screen-sm-min) {
    padding-bottom: @desktop-footer-height!important;
    padding-top: 80px;
  }
  @media(min-width: @screen-md-min) {
    padding-top: 100px;
  }
  .form-control {
    box-shadow: none;

    &::placeholder {
      color: #d7d7d7;
      opacity: 1;
      font-weight: 400;
      font-style: italic;
    }
  }
}