// =====================================================================================================================
// Text Wrapping
//
// <editor-fold>
// =====================================================================================================================
.dont-break-out {
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}
// </editor-fold>


// =====================================================================================================================
// Golden Ratio
//
// <editor-fold>
// =====================================================================================================================
.golden-image {
  position: relative;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

.golden-image--62 {
  padding-top: 62%;
}

// 16:9
.golden-image--56 {
  padding-top: 56.25%;
}
// </editor-fold>


// =====================================================================================================================
// Bootstrap Containers and Helpers
//
// <editor-fold>
// =====================================================================================================================
.container--zero-padding-xs {
  @media(max-width: @screen-xs-max) {
    // On mobile, stretch all containers across the screen.
    padding-left: 0;
    padding-right: 0;
    overflow-x: hidden;
  }
}

.container-sm {
  @media(min-width: @screen-sm-min) {
    .container-fixed();

    @media (min-width: @screen-sm-min) {
      width: @container-sm;
    }
    @media (min-width: @screen-md-min) {
      width: @container-md;
    }
    @media (min-width: @screen-lg-min) {
      width: @container-lg;
    }
  }
}

.w-100 {
  width: 100%;
}

.w-auto-sm {
  @media(min-width: @screen-sm-min) {
    width: auto;
  }
}
// </editor-fold>

// =====================================================================================================================
// Embargo Overlay
//
// <editor-fold>
// =====================================================================================================================
.embargo-overlay {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.65);
  bottom: 0;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  font-weight: normal;
  justify-content: center;
  left: 0;
  right: 0;
  padding: 10px;
  position: absolute;
  text-align: center;
  top: 0;
  z-index: 20;

  .pub-date {
    color: unset!important;
    padding-left: 0.1rem;
    text-transform: unset!important;
  }
}

.embargo-overlay__button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px 10px 10px 10px;
  background: transparent;
  border: 0;
  font-size: 19px;
}
.embargo-overlay__message {
  background-color: #CC0000;
  padding: 10px 20px;
}
// </editor-fold>


// =====================================================================================================================
// Youtube Video Container
//
// <editor-fold>
// =====================================================================================================================

/*  Wrap Youtube embeds in .youtube-video-container to make them responsive */
.youtube-video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px; height: 0; overflow: hidden;
  margin-top: 20px;
}
.youtube-video-container iframe,
.youtube-video-container object,
.youtube-video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
// </editor-fold>